/**
 * @fileoverview Login
 * Vista de Login
 * @version 2.1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * @update 25/10/2022
 */

// NPM Packages Imports.
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
//traductions
import { useTranslation } from "react-i18next";
//Estilos
import './design.css';
//redux actions
import { closeCircularProgress } from 'redux/slice/loadingMask';
//image
import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import LayoutLogin from './LayoutLogin';

// Ayvens 
import LogoAyvensS from '../../assets/Ayvens/logo.svg';

// ALD

import LogoALD from '../../assets/img/logo_ALD_black.png';

import { makeStyles } from '@material-ui/core/styles';
import styles from './redirect.styles';
import { Button, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import { styled } from '@mui/material/styles';

import IconBr from '../../assets/Ayvens/Flags/br.svg';
import IconCo from '../../assets/Ayvens/Flags/co.svg';
import IconCl from '../../assets/Ayvens/Flags/cl.svg';
import IconPe from '../../assets/Ayvens/Flags/pe.svg';
import IconMx from '../../assets/Ayvens/Flags/mx.svg';


const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Source Sans Pro',
  }
});

export default function Login() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [RegionId, setRegionId] = useState('0');
  // State.
  const [t] = useTranslation('common');

  useEffect(() => {
    ereaseCaches();
    return () => { }
  }, []);

  /**
   * Function to delete browser caches
   */
  const ereaseCaches = () => {
    if ('caches' in window) {
      caches.keys().then((names) => names.forEach((name) => caches.delete(name)));
    }
    dispatch(closeCircularProgress());
  }

  /**
   * @Fragment
   * @description Render the label for the radio button
   */

  const labelWidth = 20;

  const Label = {
    0: <p className={classes.radioLabel}><img src={IconBr} width={labelWidth}/><p style={{marginLeft: 10}}>Brasil</p></p>, // Brasil
    1: <p className={classes.radioLabel}><img src={IconMx} width={labelWidth}/><p style={{marginLeft: 10}}>México</p></p>, // México
    2: <p className={classes.radioLabel}><img src={IconPe} width={labelWidth}/><p style={{marginLeft: 10}}>Peru</p></p>, // Perú
    3: <p className={classes.radioLabel}><img src={IconCl} width={labelWidth}/><p style={{marginLeft: 10}}>Chile</p></p>, // Chile
    4: <p className={classes.radioLabel}><img src={IconCo} width={labelWidth}/><p style={{marginLeft: 10}}>Colombia</p></p>, // Colombia
  };

  /**
   * @Function GoTo
   * @description Redirect to the selected region
   */

  const GoTo = () => {
    switch (RegionId) {
      case '0':
        window.open('https://www.my.ayvens.com.br/', "_blank");
        break;
      case '1':
        window.open('https://www.my.ayvens.mx/', "_blank");
        break;
      case '2':
        window.open('https://www.my.ayvens.pe/', "_blank");
        break;
      case '3':
        window.open('https://www.my.ayvens.cl/', "_blank");
        break;
      case '4':
        window.open('https://www.my.ayvens.co/', "_blank");
        break;
      default:
        break;
    }
  }

  // Component.
  return (
    <>
      <LayoutLogin>
        <ThemeProvider theme={theme}>
          <div className="form">
            <section className={classes.container}>
              <img src={LogoAyvensS} width={250} style={{marginBlock: 10}} />
              <p className={classes.title}>{t('login.redirect.title')}</p>
              <p className={classes.description}>{t('login.redirect.description')}</p>
              <section aria-label='region' className={classes.radioGroup}>
                <FormControl>
                  <RadioGroup
                    defaultValue="0"
                    onChange={(e) => setRegionId(e.target.value)}
                    aria-labelledby="demo-customized-radios"
                    name="customized-radios"
                  >
                    <FormControlLabel value="0" control={<BpRadio />} label={Label[0]} sx={{color: '#2D2D2D', fontFamily: 'Source Sans Pro'}} />
                    <FormControlLabel value="4" control={<BpRadio />} label={Label[4]} sx={{color: '#2D2D2D', fontFamily: 'Source Sans Pro'}} />
                    <FormControlLabel value="3" control={<BpRadio />} label={Label[3]} sx={{color: '#2D2D2D', fontFamily: 'Source Sans Pro'}} />
                    <FormControlLabel value="2" control={<BpRadio />} label={Label[2]} sx={{color: '#2D2D2D', fontFamily: 'Source Sans Pro'}} />
                    <FormControlLabel value="1" control={<BpRadio />} label={Label[1]} sx={{color: '#2D2D2D', fontFamily: 'Source Sans Pro'}} />
                  </RadioGroup>
                </FormControl>
              </section>
            </section>
            <ButtonGral variant='contained' style={{width: '85%', marginBottom: 40}} onClick={GoTo}>{t('generalText.continue')}</ButtonGral>
          </div>
        </ThemeProvider>
      </LayoutLogin>
    </>
  );
}



const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px #000000',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
    ...theme.applyStyles('dark', {
      backgroundColor: '#30404d',
    }),
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
    ...theme.applyStyles('dark', {
      background: 'rgba(57,75,89,.5)',
    }),
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
    backgroundColor: '#394b59',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#072534',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#072534',
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export const ButtonGral = styled(Button)({
  "&.MuiButton-root": {
      fontFamily: "Chillax, sans-serif",
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      textTransform: 'none',
      borderRadius: 30,
      "&:hover": {
          boxShadow: '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
      },
      "&:disabled": {
          backgroundColor: '#EEEEEE',
          color: '#989A9A'
      },
      "&.MuiLoadingButton-loading": {
          color: "transparent"
      },
  },
  "&.MuiButton-containedPrimary": {
      backgroundColor: '#0F3549',
      color: 'white',
      "&:hover": {
          backgroundColor: "#072534",
          boxShadow: '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
      },
      "&:focus": {
          backgroundColor: "#072534",
          color: 'white',
      },
      "&:active": {
          backgroundColor: "#3F5D6D",
          color: 'white',
      },
      "&:disabled": {
          backgroundColor: '#EEEEEE',
      },
      "&.MuiLoadingButton-loading": {
          color: "transparent"
      },
  },
  "&.MuiButton-outlinedPrimary": {
      backgroundColor: "#FFFFFF",
      color: '#0F3549',
      borderColor: '#0F3549',
      "&:hover": {
          backgroundColor: "#E7EBED",

      },
      "&:focus": {
          backgroundColor: "#E7EBED",
      },
      "&:active": {
          backgroundColor: "#E7EBED",
          color: "#072534",
          borderColor: "#072534",
      },
      "&:disabled": {
          backgroundColor: '#EEEEEE',
          color: '#989A9A'
      },
      "&.MuiLoadingButton-loading": {
          color: "transparent"
      },
  },
  "&.MuiButton-containedSecondary": {
      backgroundColor: "#008282",
      color: 'white',
      "&:hover": {
        boxShadow: '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
        backgroundColor: '#006262',
      },
      "&:focus": {
        backgroundColor: '#006262',
      },
      "&:active": {
        backgroundColor: "#3F5D6D",
      },
      "&:disabled": {
        backgroundColor: '#EEEEEE',
      },
      "&.MuiLoadingButton-loading": {
        color: "transparent"
      },
    },
    "&.MuiButton-outlinedSecondary": {
      backgroundColor: "#FFFFFF",
      color: "#008282",
      borderColor: "#008282",
      "&:hover": {
        backgroundColor: '#D9ECEC',
        color: "#008282",
        borderColor: "#3F5D6D",
      },
      "&:focus": {
        backgroundColor: '#D9ECEC',
        color: "#008282",
        borderColor: "#008282",
      },
      "&:active": {
        backgroundColor: '#D9ECEC',
        color: "#1E2C35",
        borderColor: "#1E2C35",
      },
      "&:disabled": {
        backgroundColor: '#EEEEEE',
        color: '#989A9A'
      },
      "&.MuiLoadingButton-loading": {
        backgroundColor: '#D9ECEC',
        color: "transparent",
      },
      "&.MuiLoadingButton-loadingIndicator": {
        color: "#008282",
      }
    }

});