import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { SnackbarProvider } from 'notistack';
import Notifier from 'components/snackbar/Notifier';
//i18n
import { I18nextProvider, initReactI18next } from "react-i18next";
import backend from "i18next-xhr-backend";
import i18next from "i18next";
// core components
import Admin from "layouts/Admin.js";
import Login from "layouts/Login/Login";
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import AlertMessage from 'components/AlertMessage/AlertMessage';
//redux
import { useSelector } from 'react-redux';
//Traducciones
import es from 'translations/es.js';
import en from 'translations/en.js';
import fr from 'translations/fr.js';
import pt from 'translations/pt.js';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from 'Firebase/firebaseConfig';

import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const hist = createBrowserHistory();

const matomoInstance = createInstance({
    urlBase: 'https://t-log.sgmarkets.com/', // Reemplaza con la URL de tu instancia de Matomo
    siteId: 1739, // Reemplaza con el ID de tu sitio en Matomo
    trackerUrl: `https://t-log.sgmarkets.com/${window.location.href.includes('aldfmv2test') ? 'pruebas.php' : 'piwik.php'}`,
    srcUrl: `https://t-log.sgmarkets.com/${window.location.href.includes('aldfmv2test') ? 'pruebas.js' : 'piwik.js'}`,
    trackPageView: true,
});

const App = () => {

    const language = useSelector(state => state.configurations.language);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    /**
 * Configuración de idiomas a utilizar en la app
 * @memberof App
 */
    i18next
        .use(backend)
        .use(initReactI18next)
        .init({
            react: {
                useSuspense: true
            },
            resources: {
                es: {
                    common: es
                },
                en: {
                    common: en
                },
                fr: {
                    common: fr
                },
                pt: {
                    common: pt
                },
            },
            interpolation: { escapeValue: false },  // React already does escaping
            lng: language,                              // language to use
            fallbackLng: language,                              // language to use
        });

    return (
        <MatomoProvider value={matomoInstance}>
            <SnackbarProvider maxSnack={3}>
                <Notifier />
                <I18nextProvider i18n={i18next}>
                    <BrowserRouter history={hist}>
                        <Switch>
                            <Route exact path="*">
                            <Login />
                            </Route>
                        </Switch>
                    </BrowserRouter>
                    <LoadingScreen />
                    <AlertMessage history={hist} />
                </I18nextProvider>
            </SnackbarProvider>
        </MatomoProvider>
    )
}

export default App;