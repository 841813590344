/**
 * @fileoverview Laguage button for login
 * @version 1.0.0
 * @author César Paul Hernández Camacho
 * @date 14/04/2021
 * @copyright 2021 RESSER S.A de C.V
 */

import React from "react";
import classNames from "classnames";
// material-ui core
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Select from "@material-ui/core/Select";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Poppers from "@material-ui/core/Popper";
import Badge from "@material-ui/core/Badge";

// material-ui icons
import { MdLanguage } from "react-icons/md";

// translations
import { useTranslation } from "react-i18next";

//Styles
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

//redux
import { setLanguage } from "redux/slice/configuration";
import { useSelector, useDispatch } from "react-redux";
import { enqueueSuccessSnackbar } from "libs/Snackbar";

// styles
const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
    // styles
    const classes = useStyles();
    // state
    const [openProfile, setOpenProfile] = React.useState(null);
    //redux state
    const dispatch = useDispatch();
    const language = useSelector((state) => state.configurations.language);

    // translations
    const [t, i18n] = useTranslation("common");

    /**
     * Función encargada de realizar la actualización del idioma en el portal 
     * @param {Idioma seleccionado por el usuario} value
     * 
    */
    const onChangeLanguage = (value) => {
        i18n.changeLanguage(value);
        enqueueSuccessSnackbar(dispatch, t('profileConfiguration.account.success.1'));
        dispatch(setLanguage(value));
        setOpenProfile(null);
    };

    const handleClickProfile = (event) => {
        if (openProfile && openProfile.contains(event.target)) {
            setOpenProfile(null);
        } else {
            setOpenProfile(event.currentTarget);
        }
    };

    return (
        <>
            <Badge
                overlap="circle"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                style={{
                    backgroundColor: '#0092FF',
                    borderRadius: '6rem',
                    padding: '0.7rem',
                    position: 'fixed',
                    top: 5,
                    right: 5
                }}
                onClick={handleClickProfile}
            >
                <MdLanguage style={{ height: 30, width: 30, color: 'white' }} />
            </Badge>
            <Poppers
                open={Boolean(openProfile)}
                anchorEl={openProfile}
                transition
                disablePortal
                className={
                    classNames({ [classes.popperClose]: !openProfile }) +
                    " " +
                    classes.popperNav
                }
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="profile-menu-list-grow"
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom"
                        }}
                    >
                        <Paper>
                            <MenuList role="menu">
                                <MenuItem
                                    className={classes.dropdownItem}
                                >
                                    <p style={{ color: "#828282" }}> {t('profileConfiguration.account.selectLanguage')} </p>
                                    <Select
                                        labelId="Idioma"
                                        id="laguage"
                                        value={language}
                                        fullWidth
                                        onChange={(e) => { onChangeLanguage(e.target.value) }}
                                    >
                                        <MenuItem value={t('profileConfiguration.account.languages.0.value')}> {t('profileConfiguration.account.languages.0.text')} </MenuItem>
                                        <MenuItem value={t('profileConfiguration.account.languages.1.value')}> {t('profileConfiguration.account.languages.1.text')} </MenuItem>
                                        <MenuItem value={t('profileConfiguration.account.languages.2.value')}> {t('profileConfiguration.account.languages.2.text')} </MenuItem>
                                        {/* <MenuItem value={t('profileConfiguration.account.languages.3.value')}> {t('profileConfiguration.account.languages.3.text')} </MenuItem> */}
                                    </Select>
                                </MenuItem>
                            </MenuList>
                        </Paper>
                    </Grow>
                )}
            </Poppers>
        </>
    )
}
