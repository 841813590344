/**
 * @fileoverview Redirect Styles
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 05/11/2024
 * @copyright Industrias RESSER S.A de C.v
 * @update 02/02/2022
 */

const headerLinksStyle = () => ({
    container: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 45,
        color: '#0F3549',
        fontFamily: 'Chillax',
        "@media (max-width: 1400px)": {
            marginTop: 20,
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        marginBlock: 10
    },
    description: {
        fontSize: 15,
        fontWeight: 400,
        marginBottom: 10,
        textAlign: 'center',
    },
    radioGroup: {
        width: '65%',
        marginBottom: 20,
    },
    radioLabel: {
        display: 'flex',
    },
    buttonSend: {
        marginBlock: 20, 
        marginBottom: 45,
        "@media (max-width: 1400px)": {
            marginBottom: 20,
        }
    }
});

export default headerLinksStyle;