export default {
    generalText: {
        loadingSidebar: "Chargement des modules",
        welcome: "Bienvenue",
        header: "EXPERTS EN MOBILITÉ, GESTION DE FLOTTE ET LEASING",
        skip: "Passer",
        next: "Suivant",
        accept: "Accepter",
        cancel: "Annuler",
        call: "Appeler",
        send: "Envoyer",
        confirm: "Confirmer",
        type: "Type",
        goToModule: "Acceder",
        soon: "Prochainement",
        continue: "Continuer",
        error: "Erreur",
        advice: "Avertissement",
        correct: "Valide",
        return: "Retour",
        other: "Autre",
        delete: "Supprimer",
        yes: "Oui",
        no: "Pas",
        na: "N/A",
        edit: "mettre à jour", //TODO: Confirmar traducción
        gender: "Sexe",
        telephone: "Téléphone",
        cellphone: "Mobile",
        zipCode: "Code postal ",
        help: "Aide (Assistance 24h)",
        helpInfoTitle: "Attention",
        helpInfoBody: "Ce numéro doit s'utiliser uniquement pour une assistance 24h et appels en cas d'accident, panne ou accident.",
        novelties: "Nouveautés ",
        vehicle: "Véhicule",
        genders: ["Homme", "Femme"],
        km: "Actualiser le kilométrage",
        bad: "Mal",
        excellent: "Excelent",
        phonePlaceHolderBra: "DDD + Telephone",
        phonePlaceHolderMx: "Telephone",
        searchPlaceHolder: "Búsqueda",
        update: "Actualiser",
        register: "Solicitar registro",
        clic: "Click here",
        helpButtonPremium: "Attention Premium",
        updateButton: "mettre à jour",
        updateMessage: "Actualisation disponible! \n Actualisez la nouvelle versión de My ALD pour pouvoir profiter de toutes les améliorations qu’ ALD Latam met a votre disposition. \n (Actualisation nécessaire pour utiliser My ALD)",
        timeLineAvaliable: "Em breve, seus aplicativos serão devolvidos aqui! \n Em caso de dúvida, ligue para o Centro de Assistência ao Motorista.",
        corregir: "Corriger",
        forgotPassword: "J'ai oublié mon mot de passe",
        active: "Actif",
        finalized: "Vaincu",
        logout: "Déconnexion",
        at: ' a ',
        aldAutomotive: "ALD Automotive",
        download: 'Télécharger'
    },
    NavBar: {
        notifications: "Notifications",
        language: "Language",
        profile: "Profil",
        settings: "Settings",
        logout: "Logout"
    },
    AcceptCookies: {
        advice: "Avertissement",
        content: 'Avant de commencer, veuillez lire et accepter nos politiques et conditions actuelles',
        return: "Retour",
        continue: "Continuer",
    },
    resetSenha: {
        title: "Warning!",
        expiredToken_1: "Your temporary password has a duration of 48 hours, and this time has already expired.",
        expiredToken_2: "Please reply to the email from which you received this password informing that you need a new one as the current has expired.",
        titleSuccess: "Success!",
        titleWarning: "Warning!",
        succesToken: "Your password was successfully updated!",
        continue: "OK",
        formFieldsMessages: {
            requiredEmail: "Le champ email est obligatoire",
            errorEmail: "L'adresse électronique saisie n'est pas valide",
            shortPass: "Le mot de passe doit contenir au moins 8 et jusqu'à 15 caractères.",
            longPass: "Le mot de passe saisi est trop long",
            fieldRequired: "Le champ est obligatoire",
            samePass: "Les mots de passe doivent être les mêmes",
            passwordCriteria: "Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre, un caractère spécial (!@#$%^&*) et aucun espace."
        },
    },
    login: {
        title: "Bienvenue nous sommes heureux de vous revoir",
        userPlaceholder: "Connectez-vous avec votre nom d'utilisateur",
        userInput: "Utilisateur",
        passwordPlaceholder: "Tapez votre mot de passe",
        passwordInput: "Mot de passe",
        passwordInput_2: "Nouveau mot de passe",
        rememeberSession: "Recordarme",
        rememberPassword: "Souviens-toi de moi",
        signIn: "Connexion",
        correct: "Authentication valide",
        infoMessages: "Pour des raisons de sécurité, il est nécessaire que vous changiez votre mot de passe.",
        passwordLabel_2: "Tapez un nouveau mot de passe",
        passwordLabel_3: "Confirmez votre nouveau mot de passe",
        continue: "CONTINUER",
        tokenInfo: "Saisissez le TOKEN à 6 chiffres que nous vous avons envoyé à votre adresse électronique ",
        tokenHelp: "Si vous n'avez pas reçu le jeton, nous vous recommandons de vérifier votre dossier de courrier indésirable.",
        resendToken: "Transmettre le TOKEN",
        confirm: "Confirmer",
        receivedToken: "Vous n'avez pas reçu le TOKEN ?",
        help: "Avez-vous besoin d'aide ?",
        tokenSuccess: "Le jeton a été envoyé par courrier, vérifiez votre boîte de réception.",
        confirmHelpToken: "Votre demande d'assistance a été envoyée avec succès. Notre équipe d'assistance vous contactera pour vous aider à accéder à votre compte.",
        errors: {
            auth: [
                "Erreur d'autentification",
                "Erreur de réseau",
                "Votre compte ne contient pas d'unités assignées. Pour plus d'informations, contactez l'administration de votre flotte.",//TODO: Traducir
                "Une erreur s'est produite lors du chargement de vos informations, veuillez réessayer plus tard",
                "Nous avons rencontré un problème avec votre utilisateur. Veuillez contacter votre équipe de gestion des clients opérationnels ALD pour obtenir de l'aide!",
                "Utilisateur non détecté dans My AML Manager, veuillez demander l'accès à votre gestionnaire de compte.",
                "Vous essayez de vous connecter avec un utilisateur de type Chauffeur. Cette application est destinée exclusivement aux gestionnaires de flotte. Utilisez l'application mobile (My ALD) disponible dans la boutique en ligne de votre téléphone mobile.",
                "Une erreur s'est produite lors de l'envoi du jeton",
                "Le jeton n'est pas valide",
                "Le jeton a expiré, veuillez en générer un nouveau.",
            ],
            fields: "Les champs ne peuvent pas être vides",
            userField: "Le champ de l'identifiant ne peut être vide",
            passField: "Le champ du mot de passe ne peut être vide",
            tokenField: "Veuillez compléter le jeton avant de confirmer",
        }
    },
    categoriesNames: [
        /*01 TODO: Traducir */ "Mi Flota",
        /*04 TODO: Traducir */ "Reportes",
        /*05 TODO: Traducir */ "Mis servicios",
        /*06 TODO: Traducir */ "MyALD conductores",
        /*04*/ "MyALD Suppliers",
    ],
    dashboardNames: [
        /*00*/ "Fleet Reports",
        /*01*/ "IFRS16 Informations",
        /*02*/ "Schedule Maintenance",
        /*03*/ "Fleet Administrator",
        /*04*/ "ALD SAT",
        /*05*/ "Profile configuration",
        /*06*/ "ALDNET",
        /*07*/ "Reporte BI",
        /*08*/ "Mes Documents",
        /*09*/ "Home",
        /*10*/ "Notifications"
    ],
    profileConfiguration: {
        profile: {
            title: "Profil",
            message: "Ce champ ne modifiera que l'adresse électronique où les messages arriveront.",
            inputs: {
                name: "Nom",
                email: "Email",
                cellphone: "Nº cellulaire",
                birthdate: "Date de naissance",
                rfc: "RFC",
                postalCode: "Code postal",
                adress: "Adresse"
            },
            success: [
                /*00*/ "Image téléchargée avec succès",
                /*01*/ "Données mises à jour correctement"
            ],
            errors: [
                /*00*/ "Une erreur s'est produite lors du chargement de l'image",
                /*01*/ "Une erreur s'est produite lors de la mise à jour des données",
                /*02*/ "L'adresse mail fournie n'est pas valide",
                /*03*/ "Le nom d'utilisateur ou l'e-mail fourni n'existe pas",
                /*04*/ "Le RFC saisi n'est pas valable"
            ]
        },
        account: {
            title: "Configuration du compte",
            textDescription: "Entrez votre nouveau mot de passe, rappelez-vous qu'il doit contenir au moins une lettre majuscule et un chiffre.",
            currentPassword: "Mot de passe actuel",
            password: "Entrer le mot de passe",
            confirmPassword: "Confirmer mot de passe",
            selectLanguage: "Sélectionnez la langue de votre préférence",
            forgotPassMessage: "Entrez votre nom d'utilisateur",
            languages: [
                { value: "es", text: "Espagnol" },
                { value: "en", text: "Anglais" },
                { value: "pt", text: "Portugais" },
                { value: "fr", text: "Français" }
            ],
            success: [
                /*00*/ "Mot de passe mis à jour avec succès",
                /*01*/ "Changement de langue réussi",
                /*02*/ "E-mail de récupération de mot de passe envoyé avec succès"
            ],
            errors: [
                /*00*/ "Une erreur s'est produite lors de la mise à jour du mot de passe",
                /*01*/ "Une erreur s'est produite lors du changement de langue",
                /*02*/ "L'utilisateur ou l'e-mail de récupération ne peut pas être vide",
                /*03*/ "Le mot de passe ne peut pas contenir plus de 10 caractères",
                /*04*/ "Le mot de passe ne peut pas contenir de caractères spéciaux"
            ]
        },
        portal: {
            title: "Configuration du portail", //TODO: Comprobar traducciones
            lateralMenu: "Menu latéral fixe",
            toolTip: "Si actif: le menu lateral de gauche sera toujours visible",
        },
        alerts: {
            title: "Paramètres d'alerte",
            textDescription: "Activez et désactivez les notifications que vous souhaitez, vous pouvez également les recevoir via votre email.",
            checkbox: "Recevez également par e-mail.",
            slider1: "Services terminés",
            slider2: "Delivery",
            slider3: "Des documents",
            success: [
                /*00*/ "Alertes configurées avec succès",
                /*01*/ "Votre mot de passe a été mis à jour avec succès",
            ],
            errors: [
                /*00*/ "Une erreur s'est produite lors de la configuration des alertes",
                /*01*/ "La réinitialisation du mot de passe est possible, ce lien est valable pendant 30 minutes",
                /*02*/ "Le changement de mot de passe n'a pas pu être effectué",
                /*03*/ "Le mot de passe doit être différent des 5 derniers"
            ]
        }
    },
    header: {
        placeholderInput: "Véhicule, ID, client ...",
        search: "Chercher",
    },
    ifrs16: {
        types: [
            /*00*/ "IFRS16 Main Report",
            /*01*/ "IFRS16 Event Report"
        ],
        inputs: [
            /*00*/ "Rapports",
            /*01*/ "Date de début",
            /*02*/ "Date butoir",
            /*03*/ "Date finale"
        ],
        sendMessage: "Envoyer le rapport par e-mail",
        success: [
            /*00*/ "Rapport envoyé avec succès"
        ],
        errors: [
            /*00*/ "Une erreur s'est produite lors de l'envoi du rapport"
        ]
    },
    contracts: {
        downloadButton: "Télécharger le fichier de mise à jour",
        uploadButton: "Télécharger le fichier de mise à jour",
        titleSoon: "Proximamente",
        subtitle: 'Dans ce module, vous pouvez visualiser et gérer la mise à jour des données de vos véhicules.',
        messageSoon: "Très prochainement dans votre portail Fleet Manager, ALD mettra à votre disposition cette nouvelle fonctionnalité où vous pourrez demander la mise à jour des informations, l'annulation et l'enregistrement de nouveaux contrats. Tout cela dans le confort de votre portail Fleet Manager",
        slogan: "Attendez-vous à très bientôt, la mobilité change ...La movilidad esta cambiando.",
        uploadConfirmation: 'Etes-vous sûr de vouloir télécharger le fichier suivant : ',
        filters: {
            title: "Filtres",
            contract: "Contrat",
            client: "Client",
            date: "Date",
            plates: "Plaques",
            button: "Filtre",
            noSerie: "Nº de série",
            status: "Statut",
            clean: "Nettoyer les filtres",
            select: "Pour sélectionner"
        },
        tableColumns: {
            Brand: "Marque",
            Client: "Client",
            ClientNumber: "Nº de client",
            ContractId: "Identifiant du contrat",
            ContractNumber: "Nº de contrat",
            ContractStatus: "Statut",
            ContractStatusColombia: "Contrat de statut",
            ContractStatusString: "",
            ContractType: "Type de contrat",
            ContractedMonths: "Mois embauchés",
            CostCenter: "Centre de coûts",
            Description: "La description",
            DescriptionUO: "Description UO",
            DirectionArea: "Surface",
            EndDate: "Date finale",
            FleetGroup: "Groupe",
            FuelConsumption: "Consommation d'huile",
            KMContracted: "KM contracté",
            KMDateUpdate: "Date de mise à jour du KM",
            KMStart: "Denier KM",
            KMUpdate: "Mise à jour KM",
            LicensePlate: "Plaques",
            Management: "Entretien",
            Model: "Modèle",
            NoEco: "",
            PriceOption: "Option de prix",
            PriceWithOption: "Prix ​​avec option",
            Register: "Enregistrement",
            SellPrice: "Prix de vente",
            SerialNumber: "NºSerie (VIN/Châssis)",
            StartDate: "Date du contrat initial",
            Tyre: "",
            UserName: "Nom d'utilisateur",
            VP: "",
            VehiclePrice: "Prix ​​du véhicule",
            VehicleType: "Type de véhicule",
            VehicleTypeDescription: "Description du type de véhicule",
            id: "ID",
            Active: "Actif",
            Inactive: "Inactif",
            DateNotRegistered: "Date Non Enregistré",
            DateNotRegisteredKM: "Aucune mise à jour n'a été faite",
            CRLV: "CRLV"
        },
        success: [
            /*00*/ "Fichier téléchargé avec succès"
        ],
        errors: [
            /*00*/ "Une erreur s'est produite lors du téléchargement du fichier de mise à jour",
            /*01*/ "Une erreur est survenue lors de la remise des contrats"
        ],
        updateKM: {
            updateKM: "Mise à jour du kilométrage",
            buttonUpdateKM: "Envoyer la mise à jour du KM",
            currentKM: "Kilométrage actuel",
            newKM: "Nouveau kilométrage",
            placeholderNewKM: "Digite a nova KM",
            continue: "Voulez-vous continuer?",
            alertKMBelow: "Les plaques suivantes ont leur Nouveau kilométrage inférieur au Kilomètre actuel",
            alertKMGreater: "Les plaques suivantes ont une valeur supérieure à 450000",
            alertKMSuccess: "Les données ont été envoyées avec succès à notre équipe et seront révisées et mises à jour dans le système le plus rapidement possible. Si un problème survient lors de la mise à jour, nous vous contacterons. Merci beaucoup!",
        },
        updateKMerrors: {
            emptyPlate: "Il y a une file d'attente sans plaque assignée",
            emptyCurrentKM: "Le kilométrage actuel est vide sur la plaque",
            emptyCurrentKMHint: "On l'obtient en sélectionnant la plaque dans la liste",
            emptyNewKM: "Le nouveau kilométrage est vide sur la plaque",
            invalidKMZero: "Le nouveau kilométrage ne peut être nul sur la plaque",
            invalidKMEqual: "Le nouveau kilométrage doit être différent du kilométrage actuel de la plaque",
            failConnectionGetKM: "Une erreur s'est produite pour obtenir le kilométrage actuel, veuillez réessayer plus tard",
        },
    },
    maintenance: {
        titles: [
            /*00*/"Nouvelle maintenance",
            /*01*/"Responsable",
            /*02*/"Informez ici le chauffeur ou la personne qui accompagnera la maintenance avec l'atelier et ALD",
            /*03*/ 'Vous trouverez ci-dessous l´enregistrement des entretiens demandés précédemment. Pour demander un nouvel entretien, cliquez sur le bouton "Demander un nouveau service".',
            /*04*/ 'Coordonnées de la personne qui emmènera le véhicule au garage'
        ],
        subtitle: "Retrouvez les demandes de maintenance ouvertes dans ce module ou via l'application mobile. Informations de gestion disponibles sur BI Report",
        table: [
            /*00*/"Contrat",
            /*01*/"Plaque",
            /*02*/"Un service",
            /*03*/"Statut",
            /*04*/"Date de solicitude",
            /*05*/"Etat",
            /*06*/"Ville",
            /*07*/"Responsable",
            /*08*/"Téléphone responsable",
            /*09*/"Client",
            /*10*/"Etat",
            /*11*/"Etat",
            /*12*/"Plaque",
        ],
        buttons: [
            /*00*/"Nouveau service",
            /*01*/"Demande de service",
            /*02*/ "Demande d'un nouveau service"
        ],
        filters: [
            /*00*/"Client",
            /*01*/"Date initiale",
            /*02*/"Date finale",
            /*03*/"Filtres",
            /*04*/"Nettoyer les filtres"
        ],
        infoMessages: [
            /*00*/"Sélectionnez une date de début",
            /*01*/"Sélectionnez une date de fin",
            /*02*/"Aucun service n'a été trouvé en fonction des filtres spécifiés",
            /*03*/"Aucun service trouvé à afficher",
            /*04*/"Le billet actuel n'a pas de points de contrôle pour montrer",
        ],
        modal: [
            /*00*/ "Timeline",
            /*01*/ "Ticket SAC",
            /*02*/ "Type de service"
        ],
        groupTable: {
            headerServiceType: 'Type de Service',
            headerPlate: 'Plaque',
            headerPhone: 'Téléphone',
            headerKilometer: 'kilométrage',
            headerCountrystate: 'État',
            headerCity: 'Ville',
            headerDateOne: 'Date1',
            headerDateTwo: 'Date2',
            headerDateThree: 'Date3',
            headerRegion: 'Région',
            headerArmored: 'Blindé',
            headerRoundTrip: 'Enlèvement et livraison',
            headerDetails: 'Informations complémentaires',
            headerResponsalName: 'Nom du responsable',
            headerResponsalPhone: 'Téléphone du responsable',
            headerResponsalPhoneChile: 'Téléphone du chauffeur',
            headerResponsalEmail: 'Courriel du responsable',
            roundTrip: 'Enlèvement et livraison',
            pickupDir: 'Direction de la Collecte',
            armored: 'Blindé',
            groupService: 'Nouveau service de messe',
            groupMaintenance: 'Gestion de groupe',
            addRow: 'Ajouter une ligne',
            requestService: 'Demande de service',
            selected: 'Sélectionné',
            yes: 'Oui',
            no: 'Non',
            maintenance: 'Maintenance',
            tires: 'Pneumatiques',
            battery: 'Batterie',
            revision: 'Révision',
            repairs: 'Réparations',
            technicalRevision: 'Révision Technique',
            technicomechanicalRevision: 'Révision technicomécanique',
            placeholderPhoneMexico: "Téléphone Fleet Manager",
            placeholderResponsalPhoneMexico: "Téléphone Conducteur",
            placeholderResponsalPhoneChile: "(56+) Téléphone de 9 à 11 chiffres",
            PlaceholderResponsalPhonePeru: "Cellule conducteur (9 chiffres)",
            placeholderPhoneColombia: "Téléphone à 10 chiffres"
        },
        errors: {
            emptyFields: "Un ou plusieurs champs sont vides",
            emptyDates: "Une ou plusieurs dates sont vides",
            emptyDatesColombia: "Les 2 premières dates sont nécessaires",
            invalidDate: "La date n'est pas valide",
            invalidDateWeekend: "Invalide: Il n'est pas possible de programmer des services le week-end sur la plaque",
            invalidDateOutSchedule: "Invalide: L'heure de service ne peut pas être en dehors des heures de travail sur la plaque",
            invalidDatePass48Hours: "Invalide: Les rendez-vous doivent être pris 48 heures à l'avance sur la plaque",
            invalidDatePreviousDate: "Invalide: Le rendez-vous ne peut être fixé avant la date du jour sur la plaque",
            invalidDateOutScheduleBrasil: "Invalide: L'heure de service sélectionnée n'est pas valable, les heures de service sont de 7h du matin à 17h sur la plaque",
            invalidDateOutScheduleMexico: "Invalide: L'heure de service sélectionnée n'est pas valable, les heures de service sont de 8h du matin à 20h sur la plaque",
            invalidDateOutSchedulePeru: "Invalide: L'heure de service sélectionnée n'est pas valable, les heures de service sont de 7h du matin à 18h sur la plaque",
            invalidDateOutScheduleChile: "Invalide: L'heure de service sélectionnée n'est pas valable, les heures de service sont de 8h du matin à 17h sur la plaque",
            invalidDateOutScheduleColombia: "Invalide: L'heure de service sélectionnée n'est pas valable, les heures de service sont de 8h du matin à 18h sur la plaque",
            invalidDateWeekendColombia: "Invalide: Il n'est pas possible de programmer des services le dimanche sur la plaque",
            invalidEmail: "L'e-mail de la personne responsable a un format invalide sur la plaque",
            repeatedPlate: "L'insigne est répété.",
            rowError: "Erreur d'envoi de données dans la plaque",
            failConnection: "Une erreur de connexion s'est produite, veuillez réessayer plus tard",
            successConnection: "Toutes les données ont été envoyées avec succès",
            successRowSend: "Envoi réussi des données sur la plaque",
            repeatedService: "La (les) plaque(s) suivante(s) a un service similaire en cours",
            emptyServiceType: "Le champ Type de Service est vide sur la plaque",
            emptyPlate: "Il y a une file d'attente sans plaque assignée",
            emptyPhone: "Le champ Téléphone est vide sur la plaque",
            emptyKilometer: "Le champ Kilométrage est vide sur la plaque",
            emptyCountryestate: "Le champ État est vide sur la plaque",
            emptyCity: "Le champ Ville est vide sur la plaque",
            emptyRegion: "Le champ Région est vide sur la plaque",
            emptyDate1: "Le champ Date1 est vide sur la plaque",
            emptyDate2: "Le champ Date2 est vide sur la plaque",
            emptyDate3: "Le champ Date3 est vide sur la plaque",
            emptyDates: "Il est nécessaire de sélectionner au moins une date pour la plaque",
            emptyArmored: "Indiquer le champ Véhicule blindé sur la plaque",
            emptyRoundTrip: "Indiquez que le champ Take and Bring est vide sur le panneau",
            emptyPickupDir: "Le champ Direction de la Collecte est vide sur la plaque",
            emptyResponsalName: "Le champ Nom du Responsable est vide sur la plaque",
            emptyResponsalPhone: "Le champ Téléphone du Responsable est vide sur la plaque",
            emptyResponsalEmail: "Le champ Courriel du Responsable est vide sur la plaque",
            limitPhoneChile: "Le téléphone fourni n'est pas valide, il doit avoir un minimum de 9 ou un maximum de 11 chiffres dans le brevet",
            limitPhoneRSChile: "Le téléphone du conducteur fourni n'est pas valide, il doit avoir un minimum de 9 ou un maximum de 11 chiffres dans le brevet",
            limitPhonePeru: "Le téléphone fourni n'est pas valide, il doit avoir un minimum de 9 chiffres sur la plaque",
            limitPhoneRSPeru: "Le téléphone du chauffeur fourni n'est pas valide, il doit avoir un minimum de 9 chiffres sur la plaque",
            limitPhoneColombia: "Le numéro de téléphone fourni n'est pas valide, il doit avoir un minimum de 10 caractères sur la plaque",
            limitPhoneRSColombia: "Le numéro de téléphone du responsable fourni n'est pas valide, il doit avoir un minimum de 10 caractères sur la plaque",
            errorBI: "Désolé, une erreur s'est produite sur notre serveur lors du chargement du BI, veuillez réessayer plus tard."
        }
    },
    modalTicketBr: {
        alertMessage: {
            title: "Attention",
            description: "The dates of your preference for scheduling Maintenance, Periodic Review or Tires Change must not exceed 45 days in advance."
        },
        inputs: [
            /*00*/"DDD + Téléphone",
            /*01*/"Kilométrage",
            /*02*/"Etat",
            /*03*/"Ville",
            /*04*/"Sélectionnez la région de votre choix pour le service",
            /*05*/"Date tentavie 1",
            /*06*/"Date tentavie 2",
            /*07*/"Date tentavie 3",
            /*08*/"Voiture blindée?",
            /*09*/"aller-retour?",
            /*10*/"Saisissez l'adresse de retrait du véhicule en cas d'option pour le service aller-retour",
            /*11*/"Indiquez l'adresse de retour du véhicule en cas d'option pour le service aller-retour",
            /*12*/"Renseigner l'adresse pour la collecte et le retour du véhicule en cas d'option pour le service Leva e Traz",
            /*13*/"Information additionnelle",
            /*14*/"Nom",
            /*15*/"Email",
            /*16*/"Décrivez ici si le véhicule a un bruit différent ou des problèmes supplémentaires tels que la climatisation et autres ...",
            /*17*/"Plaque",
            /*18*/"DDD + Téléphone responsable"
        ],
        levaETraz: [
            /*00*/"Aller-retour",
            /*01*/"Au",
            /*02*/"Aller",
            /*03*/"Retour",
            /*04*/"Aller-retour"
        ],
        errorMessages: [
            /*00*/"Veuillez saisir un numéro de téléphone ou de portable",
            /*01*/"Veuillez sélectionner un véhicule avant de continuer",
            /*02*/"Veuillez saisir un nom de responsable du service",
            /*03*/"Veuillez saisir le courrier électronique du responsable du service",
            /*04*/"Veuillez saisir une adresse e-mail valide pour le responsable du service",
            /*05*/"Veuillez saisir un numéro de téléphone mobile pour le fournisseur de services",
            /*06*/"Remplissez le champ kilométrage",
            /*07*/"Sélectionnez un état pour planifier le service",
            /*08*/"Sélectionnez une ville pour planifier le service",
            /*09*/"Veuillez remplir le champ d'informations supplémentaires",
            /*10*/"Indiquez si la voiture est blindée",
            /*11*/"Sélectionnez si vous avez besoin du service Aller and retour",
            /*12*/"Attention, les dates ne peuvent pas être les mêmes",
            /*13*/"Entrez l'adresse pour effectuer le service Aller and retour",
            /*14*/"Veuillez sélectionner la région dans laquelle vous souhaitez effectuer le service",
            /*15*/"Les rendez-vous sont disponibles de lundi a vendredi",
            /*16*/"L'heure du service selectionné n'est pas valide. De 7h a 17h uniquement",
            /*14*/"Por favor seleccione una fecha para agendar el servicio",
            /*18*/"Nous sommes désolés pour le moment, nous ne pouvons pas enregistrer le service demandé",
            /*19*/"Désolé, une erreur s'est produite sur notre serveur lors de l'enregistrement du service demandé, veuillez réessayer plus tard",
            /*20*/"Le rendez-vous doit être demandé 48 heures à l'avance",
            /*21*/"La date de planification ne peut pas être inférieure à la date du jour"
        ],
        successMessages: [
            /*00*/"Votre demande a été recue avec succès. D'ici peu, un de nos consultant vous contactera pour vous donner les details de votre rendez-vous"
        ],
        infoMessages: [
            /*00*/"Notre équipe évaluera l'éligibilité au produit Leva et apportera selon le contrat de votre entreprise",
            /*01*/"Le contrat sélectionné est attribué à un chauffeur, ajoutez les informations manquantes de la personne responsable du service",
            /*02*/"Le contrat est attribué à plus d'un chauffeur, ajoutez manuellement les informations de la personne responsable du service",
            /*03*/"Le contrat n'est attribué à aucun conducteur, entrez manuellement les informations de la personne responsable du service"
        ]
    },
    modalTicketGnr: {
        inputs: [
            /*00*/"Plaque",
            /*01*/"DDD + Téléphone",
            /*02*/"Kilométrage",
            /*03*/"Etat",
            /*04*/"Ville",
            /*05*/"Date tentavie 1",
            /*06*/"Date tentavie 2",
            /*07*/"Information additionnelle",
            /*08*/"Nom",
            /*09*/"Email",
            /*10*/"Décrivez ici si le véhicule a un bruit différent ou des problèmes supplémentaires tels que la climatisation et autres ...",
            /*11*/"Etat", //Nota: Esta traducción no se debe eliminar es la traducción al PT de Departamento que usan en Colombia y Perú
            /*12*/"Etat", //Nota: Esta traducción no se debe eliminar es la traducción al PT de Regíon que usan en Chile
            /*13*/"Type d'entretien",
            /*14*/ "Téléphone d'entreprise",
            /*15*/ "Corporate email"
        ],
        serviceType: [
            /*00*/"Révision préventive",
            /*01*/"Révision corrective"
        ],
        errorMessages: [
            /*00*/"Veuillez saisir un numéro de téléphone ou de portable",
            /*01*/"Veuillez sélectionner un véhicule avant de continuer",
            /*02*/"Veuillez saisir un nom de responsable du service",
            /*03*/"Veuillez saisir le courrier électronique du responsable du service",
            /*04*/"Veuillez saisir une adresse e-mail valide pour le responsable du service",
            /*05*/"Veuillez saisir un numéro de téléphone mobile pour le fournisseur de services",
            /*06*/"Remplissez le champ kilométrage",
            /*07*/"Sélectionnez un état pour planifier le service",
            /*08*/"Sélectionnez une ville pour planifier le service",
            /*09*/"Veuillez remplir le champ d'informations supplémentaires",
            /*10*/"Attention, les dates ne peuvent pas être les mêmes",
            /*11*/"Les rendez-vous sont disponibles de lundi a vendredi",
            /*12*/"O horário de serviço selecionado não é válido, o horário de funcionamento é das 8h até às 20h",
            /*13*/"Ce véhicule a déjà un service similaire en cours",
            /*14*/"Veuillez sélectionner une date pour planifier le service",
            /*15*/"No se pueden agendar servicios los domingos",
            /*16*/"La hora de servicio seleccionada no es valida, el horario de atención en sabados es de 8:00 am a 14:00 pm",
            /*17*/"O horário de serviço selecionado não é válido, o horário de funcionamento é das 8h até às 14h",
            /*18*/"Selectionnez un type d'entretien",
            /*19*/"Le rendez-vous doit être demandé 48 heures à l'avance",
            /*20*/"La date de planification ne peut pas être inférieure à la date du jour",
            /*21*/"Sélectionnez un état pour planifier le service",
            /*22*/"Sélectionnez un état pour planifier le service",
            /*23*/"L'heure de service sélectionnée n'est pas valide, les heures d'ouverture sont de 8h30 à 13h00 et de 14h00 à 17h30.",
            /*24*/"L'heure de service sélectionnée n'est pas valide, les heures d'ouverture le samedi sont de 8h30 à 13h00."
        ],
        successMessages: [
            /*00*/"Votre demande a été recue avec succès. D'ici peu, un de nos consultant vous contactera pour vous donner les details de votre rendez-vous"
        ],
        infoMessages: [
            /*00*/"Le contrat sélectionné est attribué à un chauffeur, ajoutez les informations manquantes de la personne responsable du service",
            /*01*/"Le contrat est attribué à plus d'un chauffeur, ajoutez manuellement les informations de la personne responsable du service",
            /*02*/"Le contrat n'est attribué à aucun conducteur, entrez manuellement les informations de la personne responsable du service"
        ],
        noContractMessage: "Aucun contrat disponible"
    },
    checkPointsTimeline: {
        titles: [
            /*00*/ "Ticket ouvert",
            /*01*/ "Ordonnancement",
            /*02*/ "Êtes-vous arrivé à l'atelier?",
            /*03*/ "Budget en préparation!",
            /*04*/ "Budget d'approbation",
            /*05*/ "Prévision de livraison pour votre véhicule",
            /*06*/ "Avez-vous retiré votre véhicule?",

            /*07*/ "Check in realizado",
            /*08*/ "Budget envoyé à ALD",
            /*09*/ "Budget approuvé",
            /*10*/ "Départ terminé!",
            /*11*/ "budget échoué"
        ],
        generalMessages: [
            /*00*/ "Nous y travaillons! \n Vous recevrez bientôt vos informations de planification \n",
            /*01*/ "Enregistrez-vous et assurez plus d'agilité!",
            /*02*/ "L'atelier analyse votre véhicule \n et vous enverra un devis pour approbation",
            /*03*/ "Nous y travaillons! \n Nous approuverons prochainement l'entretien de votre véhicule!",
            /*04*/ "fermeture prévue",
            /*05*/ "Faça Check out aqui!",
            /*06*/ "Prêt à recevoir le délai de livraison de votre véhicule",
            /*07*/ "Livraison de votre véhicule ",
            /*08*/ "En cas de doute, appelez notre Driver Support Center",
            /*09*/ "Billet annulé",
            /*10*/ "Cliquez ici, enregistrez-vous et assurez plus d'agilité tout au long du processus",
            /*11*/ "\n \n * Avant de retirer votre véhicule, vérifiez l'horaire avec l'atelier, car lors de l'entretien du véhicule, des événements imprévus peuvent survenir.",
            /*12*/ "Cliquez ici et passez à la caisse!"
        ],
        ticketType: [
            /*00*/ "Entretien | Examen périodique",
            /*01*/ "Maintenance préventive",
            /*02*/ "Maintenance",

            /*03*/ "Maintenance corrective",
            /*04*/ "Analyse technique",

            /*05*/ "Pneus",
            /*06*/ "Pneus",

            /*07*/"Revue technique mécanique",

            /*08*/"Réparations",

            /*09*/"Batterie",
        ]
    },
    bireport: {
        indicators: "Indicateurs",
        canbus: "Canbus",
        messageSoon: "Très prochainement dans votre portail Fleet Manager, ALD mettra à votre disposition cette nouvelle fonctionnalité où vous pourrez consulter les rapports sur votre flotte. Tout cela dans le confort de votre portail Fleet Manager"
    },
    aldnet: {
        title: "ALD NET",
        message: "ouvrir aldnet dans un nouvel onglet de navigateur",
        instrucctions: "Le login et le mot de passe pour accéder à ALD NET sont les mêmes que ceux que vous avez utilisés pour accéder à l'ancien portail. Si vous ne vous souvenez pas, cliquez sur «J'ai oublié mon mot de passe»",
        button: "Aller à ALDNET"
    },
    alertMessage: {
        message: "La session est terminée pour continuer à utiliser la plateforme, il est nécessaire de se reconnecter"
    },
    PrivacyPolicy: {
        terms: "Conditions d'utilisation",
        privacy: 'Politique de Confidentialité',
        cookies: 'Politique en Matière de Cookies',
        advice: 'Avant de commencer, veuillez lire et accepter nos politiques et conditions actuelles',
        accept: "J'ai lu et j'accepte"
    },
    documents: {
        messageSoon: "Très prochainement ici sur votre portail MyALD pour les gestionnaires de flotte, nous mettrons à votre disposition cette nouvelle fonction avec laquelle vous pourrez gérer et distribuer des documents parmi vos chauffeurs rapidement et confortablement",
        headers: [
            /*00*/ "Tapez",
            /*01*/ "Titre",
            /*02*/ "Catégorie",
            /*03*/ "Informations sur l'expédition",
            /*04*/ "Notes",
            /*05*/ "Type de document"
        ],
        title: "Mes documents",
        subtitle: "Historique des contrats",
        modalTitle: "Télécharger des documents",
        buttonTitle: "Télécharger le fichier",
        deleteMessage: "Êtes-vous sûr de vouloir supprimer le document ?",
        modal: [
            /*00*/ "Nom du document",
            /*01*/ "Catégorie",
            /*02*/ "Remarques"
        ],
        options: [
            /*00*/ "Gestionnaire de flotte",
            /*01*/ "Chauffeur",
            /*02*/ "Général"
        ],
        alertMessages: [
            /*00*/ "Aucun document trouvé",
            /*01*/ "Document supprimé avec succès",
            /*02*/ "Vous devez sélectionner un fichier à télécharger",
            /*03*/ "Vous devez sélectionner au moins un client pour télécharger le document",
            /*04*/ "L'extension du fichier n'est pas valide, elle doit être .pdf",
            /*05*/ "L'extension du fichier n'est pas valide, elle doit être .xlsx",
            /*06*/ "Le nom du fichier est invalide, il ne peut pas contenir de caractères spéciaux",
            /*07*/ "La taille du fichier est trop importante (3 Mo maximum)",
            /*08*/ "Nom de fichier non valide",
        ]
    },
    gloveBox: {
        documentType: "Type de document",
        gloveBoxApp: "Glove Box App (MI AUTO)",
        documentApp: "Documentos App",
        descriptionDocuments: "Aqui você pode publicar informativos (políticas/infográficos, etc) para os condutores de sua frota",
        descriptionGloveBox: "Aqui ficam os documentos do carro ",
        upload: "Charger le fichier",
        saving: "Chargement",
        save: "téléchargé",
        saveFile: "SAUVER",
        alertMessages: [
            /*01*/ "Il y a eu une erreur. Veuillez réessayer",
            /*02*/ "Le téléchargement du fichier a été effectué avec succès !",
            /*03*/ "Aucun contrat n'a été trouvé",
            /*04*/ "L'extension du fichier n'est pas valide, elle doit être .pdf",
        ]
    },
    Notification: {
        description: "Aqui você pode publicar avisos gerais, notícias rápidas e eventos para os condutores de sua frota. As notificações poderão ser visualizadas em \"Notificações\" no menu lateral do app do condutor",
        deleteNotificationTitle: "Excluir notificação",
        deleteNotificationText: "Tem certeza que deseja excluir esta notificação?",
        noNotifications: "Aucune notification trouvée",
        deleteNotificationSuccess: "Notification supprimée avec succès",
        appendix: "Appendice",
        category: "Catégorie",
        title: "Titre",
        date: "Date de publication",
        createDate: "Date de génération",
        link: "Lien additionnel",
        newNotification: "Nouvelle notification",
        addNotification: {
            addNotification: "Créer une nouvelle notification",
            document: "Fichier PDF",
            title: "Titre *",
            type: "Type *",
            description: "Description",
            date: "Date de publication",
            hourDate: "Heure de publication",
            link: "Lien additionnel",
            selectCompany: "Sélectionner les entreprises",
            userType: "Type d'utilisateur",
            userFM: "FM",
            userDriver: "Drivers",
            selectAll: "Sélectionner tout",
            accept: "Salvar",
            labelMaxLenght: "Maximum 90 caractères",
            labelUsers: "Sélectionnez tous les utilisateurs qui doivent recevoir cette communication ou faites-les glisser ici.",
            customerCare: "Assinatura do cliente"
        },
        AlertsMassage: [
            "Une erreur s'est produite lors de la création de votre notification.", // 0
            "Notification supprimée avec succès", // 1
            "Téléchargement de la notification réussi !", // 2
            "Aucune notification trouvée créée", // 3
            "Ajoutez les informations manquantes pour télécharger la notification.", //4
            "Pas de notifications pour l'instant"
        ],
        FilterLabels: {
            category: "Catégorie",
            title: "Titre",
            createDate: "Date de création",
            publishDate: "Date de publication",
        }
    },
    AldSacTimeLine: {
        titles: [
            /*00*/ "Ticket ouvert",
            /*01*/ "Ordonnancement",
            /*02*/ "Êtes-vous arrivé à l'atelier?",
            /*03*/ "Budget en préparation!",
            /*04*/ "Budget d'approbation",
            /*05*/ "Prévision de livraison pour votre véhicule",
            /*06*/ "Avez-vous retiré votre véhicule?",

            /*07*/ "Check in realizado",
            /*08*/ "Budget envoyé à ALD",
            /*09*/ "Budget approuvé",
            /*10*/ "Départ terminé!",
            /*11*/ "budget échoué"
        ],
        generalMessages: [
            /*00*/ "Nous y travaillons! \n Vous recevrez bientôt vos informations de planification \n",
            /*01*/ "Enregistrez-vous et assurez plus d'agilité!",
            /*02*/ "L'atelier analyse votre véhicule \n et vous enverra un devis pour approbation",
            /*03*/ "Nous y travaillons! \n Nous approuverons prochainement l'entretien de votre véhicule!",
            /*04*/ "fermeture prévue",
            /*05*/ "Faça Check out aqui!",
            /*06*/ "Prêt à recevoir le délai de livraison de votre véhicule",
            /*07*/ "Livraison de votre véhicule ",
            /*08*/ "En cas de doute, appelez notre Driver Support Center",
            /*09*/ "Billet annulé",
            /*10*/ "Cliquez ici, enregistrez-vous et assurez plus d'agilité tout au long du processus",
            /*11*/ "* Avant de retirer votre véhicule, vérifiez l'horaire avec l'atelier, car lors de l'entretien du véhicule, des événements imprévus peuvent survenir.",
            /*12*/ "Cliquez ici et passez à la caisse!"
        ],
        alerts: [
            // Alerta para cancelación de servicio
            /*00*/ 'Cancelar',
            /*01*/ 'Voulez-vous vraiment annuler le service?',

            // Alerta para reagendar servicio
            /*02*/ 'Replanifier',
            /*03*/ 'Voulez-vous replanifier le service?',

            // Alerta para CheckIn
            /*04*/ 'Check in',
            /*05*/ 'Vous souhaitez informer ALD que vous avez votre véhicule à l' + "'" + 'atelier ou chez le concessionnaire?',
            /*06*/ 'Oui',
            /*07*/ 'Normaliser à nouveau',
            /*08*/ 'No',

            // Alerta al cancelar servicio correctamente
            /*09*/ 'Votre demande d' + "'" + 'annulation a été envoyée avec succès!',

            // Alerta para error al cancelar servicio
            /*10*/ 'Une erreur s' + "'" + 'est produite lors de la tentative d' + "'" + 'annulation du service demandé. \nRéessayez et si le problème persiste, contactez le Driver Support Center pour demander l' + "'" + 'annulation!',

            // Alerta al reagendar servicio correctamente
            /*11*/ 'Votre demande de replanification a été envoyée correctement. \nDès que notre équipe planifie le service, vous recevrez une confirmation sur le calendrier de votre billet. Restez à l' + "'" + 'écoute!',

            // Alert para error al reagendar servicio
            /*12*/ 'Une erreur s' + "'" + 'est produite lors de la tentative de replanification du service. \nRéessayez et si le problème persiste, contactez le Driver Support Center.',

            //Check-in/out
            /*13*/ 'Confirmation avec succès',

            //Error en la obtención de tickets
            /*14*/ 'Une erreur sest produite lors de lobtention des informations de service \nRéessayez et si le problème persiste, contactez le Driver Support Center.',

            //Mensajes para checkIn y CheckOut
            /*15*/ "Check In",
            /*16*/ "Voulez-vous confirmer a ALD l'entrée de votre véhicule au point de service désigné ?",
            /*17*/ "Check Out",
            /*18*/ "Voulez-vous confirmer a ALD que votre véhicule vous a été livré?",
            /*19*/ "Il s'est produit une erreur dans la confirmation"
        ],
        Button: [
            /*00*/ 'annuler un rendez-vous',
            /*01*/ 'Demande d' + "'" + 'annulation de service',
            /*02*/ 'Demande de replanifier le service',
            /*03*/ 'Check In',
            /*04*/ 'Check Out'
        ]
    },
    home: {
        products: [
            /*00*/ "Intelligence",
            /*01*/ "Mobilité",
            /*02*/ "Performance",
            /*03*/ "Durabilité",
            /*04*/ "Produits ALD",
            /*05*/ "Apprenez-en plus sur nos produits !",
            /*06*/ "Plus de produits bientôt !!!"
        ],
        blog: [
            /*00*/ "ALD Nouvelles",
            /*01*/ "ALD est là pour vous aider, venez consulter notre vaste blog où vous trouverez des idées et des solutions de mobilité.",
            /*02*/ "Cliquez et apprenez-en plus..."
        ],
        news: [
            /*00*/ "Recevez les dernières nouvelles sur la mobilité, pour en savoir plus sur la façon dont l'ALD peut vous aider, vous et votre flotte, à vous développer."
        ]
    },
    Months: {
        completos: [
            "Janvier",
            "Fevrier",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre"
        ],
        abreviatura: [
            "Jan",
            "Fév",
            "Mar",
            "Avr",
            "Mai",
            "Jun",
            "Jul",
            "Aoû",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ]
    },
    ModuleBI: {
        title: 'REMARQUER',
        Paragraph_1: 'Votre utilisateur MyALD ne dispose pas encore des autorisations nécessaires pour accéder aux rapports de flotte.',
        Paragraph_2: 'Après la libération de votre accès au portail, les autorisations d’affichage des rapports prennent 24 heures pour être activées. Si ce délai a expiré, nous vous demandons de nous contacter en :',
        Paragraph_3: 'Informer que vous ne pouvez pas afficher les rapports de flotte dans MyALD Manager.'
    },
    ModuleAgreements: {
        BudgetApproval: 'Gestionar Presupuesto',
        ChooseCustomer: 'Debe seleccionar un cliente para ver los datos',
        DoesntHaveBudget: 'Este cliente seleccionado no tiene nuevos presupuestos para aprobar',
        Filters: {
            clean: 'Limpiar Filtros',
            clients: 'Clientes',
            plates: 'Placas',
            filters: 'Filtros',
            pedding: 'Pendientes',
            approved: 'Aprobados',
            rejected: 'Rechazados',
            cancel: 'Cancelar',
            apply: 'Aplicar Filtros',
        },
        table: {
            idSat: 'ID SAT',
            plate: 'Placa',
            model: 'Modelo',
            enterprise: 'Empresa',
            dateService: 'Cita de servicio',
            description: 'Descripción',
            price: 'Precio (con IVA)',
            provider: 'Proveedor',
            status: 'Estatus',
            actions: 'Acciones',
            sfTicket: 'Ticket (SF Case)',
        },
        actions: {
            view: 'Ver Presupuesto',
            comments: 'Comentarios',
            history: 'Historial',
            attachment: 'Ver documento',
        },
        budget: {
            id: 'Nº Orçamento',
            vehicle: 'Veículo',
            plate: 'Placa',
            km: 'KM',
            vin: 'Chassis',
            driver: 'Condutor',
            customer: 'Empresa',
            supplier: 'Fornecedor',
            workshop: 'Oficina',
            quantity: 'Quantidade',
            description: 'Descrição',
            price: 'Preço (com IVA)',
            priority: 'Prioridade',
            rejected: 'Reprovação total',
            partialAproval: 'Autorização parcial',
            fullAproval: 'Autorização completa',
            salesForceCaseNumber: 'Nº Caso SF',
            low: 'Baixar',
            medium: 'Média',
            high: 'Alta',
            parts: 'Partes',
            manoObra: 'Mano de Obra',
            typeCost: 'Tipo de Costo',
            maintenance: 'Manutenção',
        },
        comments: {
            comments: 'Comentarios',
            noComments: 'No hay comentarios',
            hasNoComments: 'Este presupuesto no tiene comentarios',
            insertComments: 'Agregar Comentarios',
            writeComments: 'Escribir observaciones aqui',
        },
        alerts: {
            selectItems: 'Selecciona al menos un elemento para continuar.',
            procesingRequest: 'ALD se encuentra atendiendo su aprobación, no es necesario volver a autorizar o rechazar el presupuesto.',
            workingNewBudget: 'ALD se encuentra generando un nuevo presupuesto para usted, una vez este disponible podrá visualizarlo en el portal.',
            selectOneItem: 'Selecciona al menos un elemento para continuar.',
            rejectedBudget: [
                'Esta por rechazar el presupuesto por completo.', // 0
                'Desea continuar?', //1
            ],
            fullApprovalBudget: [
                'Esta por realizar la aprobación completa de su presupuesto', // 0
                'con un costo total de', //1
                'esta seguro de continuar?' //2
            ],
            partialBudget: [
                'Estás intentando enviar una aprobación parcial. Estaremos enviando su solicitud parcial a ALD.', // 0
                'Esta seguro de continuar con la solicitud? ' // 1
            ],
            itemsRejected: '* Ítems Rechazados por el Fleet Manager:',
            itemsApproved: '* Ítems Aprobados por el Fleet Manager:',
            errorFile: "Le fichier n'a pas pu être téléchargé, la structure de la pièce jointe n'est pas valide",
            emptyAttachment: "Aucune pièce jointe n'est encore disponible",
            errorAttachment: 'Ocurrió un error al obtener el Attachment',
        }
    }
}